import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import React, {useEffect, useState} from 'react';
import Api from '../Api';

export default function CheckoutForm(props) {
	const {payAmount} = props;

	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			'payment_intent_client_secret'
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
			// here what ever you create api call, call it from here and send paymentIntent parameter with api
			setIsLoading(false);
			Api.paymentStripeSuccess({paymentIntent: paymentIntent}).then(
				(response) => {
					if (
						response?.data?.success &&
						paymentIntent?.status === 'succeeded'
					) {
						console.log('Payment Successfull Completed');
						window.location.href = '/student-dashboard-1';
					}
				}
			);
			switch (paymentIntent.status) {
				case 'succeeded':
					setMessage('Payment succeeded!');
					break;
				case 'processing':
					setMessage('Your payment is processing.');
					break;
				case 'requires_payment_method':
					setMessage('Your payment was not successful, please try again.');
					break;
				default:
					setMessage('Something went wrong.');
					break;
			}
		});
	}, [stripe]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		setIsLoading(true);

		const {error} = await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				// return_url: 'http://localhost:3000/paymentStripe'
				return_url: 'https://take5music.live/paymentStripe'
			}
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		if (error) {
			if (error.type === 'card_error' || error.type === 'validation_error') {
				setMessage(error.message);
			} else {
				setMessage('An unexpected error occured.');
			}
			setIsLoading(false);
		}
	};

	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" />
			<button disabled={isLoading || !stripe || !elements} id="submit">
				<span id="button-text">
					{isLoading === false && (
						<div className="spinner" id="spinner">
							{' '}
							${payAmount} Pay Now
						</div>
					)}
				</span>
			</button>
			{/* Show any error or success messages */}
			{message && <div id="payment-message">{message}</div>}
		</form>
	);
}
