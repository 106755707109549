import React, {useEffect, useState} from 'react';
import Footer from './Footer';
import Header from './Header';
import {Link} from 'react-router-dom';
import Axios from 'axios';
const Pricing = (props) => {
	const [isIndia, setCountry] = useState(-1);
	useEffect(() => {
		const getData = async () => {
			const res = await Axios.get('https://geolocation-db.com/json/');
			if (res.data.country_code === 'IN') {
				localStorage.setItem('isIndia', 0);
				setCountry(0);
			} else {
				localStorage.setItem('isIndia', 1);
				setCountry(1);
			}
		};
		getData();
	}, []);

	const link = !!JSON.parse(window.localStorage.getItem('authData'));

	return (
		<>
			<Header />

			<div class="take_section take_pricing_wrapper">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="take_heading_wrapper text-left">
								<h2 className="h2">Our Subscription Plans</h2>
								<p>
									Let's get you started with Take5, simple all-inclusive plans
									are as below.
								</p>
							</div>
							{isIndia === 0 && (
								<div class="row justify-content-center">
									<div class="col-lg-4 col-md-4 col-sm-6 ">
										<div class="take_pricing_box">
											<div class="take_pricetitle">
												<h2 className="h2">Monthly </h2>
												<span class="take_pricetag">
													<svg
														width="17"
														height="16"
														viewBox="0 0 17 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<rect
															x="0.919922"
															y="0.677429"
															width="15.1613"
															height="15.1613"
															rx="3.03226"
															fill="#F68B00"
														/>
													</svg>
												</span>
											</div>
											<div class="take_price">
												<h2 className="h2">&#8377;2699</h2>
												<p>Per Month</p>
											</div>

											<ul>
												<li>12 Live Classes</li>
												<li>1 Month Duration</li>
											</ul>
											<div class="take_price_btn">
												<Link to={link ? '/student-dashboard' : '/login'}>
													<button class="take_btn price_btn">Select</button>
												</Link>
											</div>
										</div>
									</div>
									<div class="col-lg-4 col-md-4 col-sm-6 ">
										<div class="take_pricing_box pink">
											<div class="take_pricetitle">
												<h2 className="h2">Quarterly </h2>
												<span class="take_pricetag">
													<svg
														width="25"
														height="24"
														viewBox="0 0 25 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M24.223 8.49487L17.0244 7.44869L13.8065 0.924931C13.7186 0.746314 13.574 0.60172 13.3954 0.513829C12.9474 0.292685 12.403 0.476972 12.1791 0.924931L8.96112 7.44869L1.76256 8.49487C1.5641 8.52322 1.38264 8.61678 1.24372 8.75854C1.07577 8.93117 0.983218 9.16341 0.986408 9.40423C0.989598 9.64506 1.08827 9.87477 1.26073 10.0429L6.46898 15.1207L5.23851 22.2909C5.20965 22.4577 5.22811 22.6292 5.29179 22.7861C5.35546 22.9429 5.46181 23.0787 5.59877 23.1782C5.73573 23.2777 5.89782 23.3368 6.06667 23.3488C6.23551 23.3609 6.40435 23.3254 6.55404 23.2463L12.9928 19.8611L19.4315 23.2463C19.6073 23.3399 19.8114 23.3711 20.007 23.3371C20.5004 23.252 20.8321 22.7842 20.747 22.2909L19.5165 15.1207L24.7248 10.0429C24.8666 9.90396 24.9601 9.72251 24.9885 9.52404C25.065 9.02789 24.7191 8.56859 24.223 8.49487Z"
															fill="#FF697B"
														/>
													</svg>
												</span>
											</div>
											<div class="take_price">
												<h2 className="h2">&#8377;7499</h2>
												<p>For 3 Months</p>

												<span class="popular">Most Popular</span>
											</div>

											<ul>
												<li>36 Live Classes</li>
												<li>3 Months Duration</li>
											</ul>
											<div class="take_price_btn">
												<Link to={link ? '/student-dashboard' : '/login'}>
													<button class="take_btn price_btn">Select</button>
												</Link>
											</div>
										</div>
									</div>
									<div class="col-lg-4 col-md-4 col-sm-6 ">
										<div class="take_pricing_box blue">
											<div class="take_pricetitle">
												<h2 className="h2">Semestral</h2>
												<span class="take_pricetag">
													<svg
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M4.69079 0.971786C4.66328 0.971533 4.63614 0.978128 4.61181 0.990978C4.58749 1.00383 4.56674 1.02253 4.55144 1.04539C4.53614 1.06826 4.52677 1.09457 4.52418 1.12196C4.52158 1.14935 4.52584 1.17695 4.53657 1.20228L4.63192 1.42739L6.31921 5.36163C6.33067 5.38839 6.34898 5.41166 6.37229 5.4291C6.3956 5.44654 6.42309 5.45753 6.452 5.46098C6.48091 5.46442 6.51021 5.4602 6.53697 5.44873C6.56373 5.43725 6.58699 5.41894 6.60443 5.39563L9.71865 1.23711C9.73713 1.21247 9.74838 1.18318 9.75115 1.1525C9.75391 1.12183 9.74808 1.091 9.73431 1.06345C9.72054 1.03591 9.69937 1.01274 9.67317 0.996552C9.64697 0.980362 9.61679 0.971786 9.58599 0.971786H4.69079Z"
															fill="#1AADFF"
														/>
														<path
															d="M17.0528 2.05214L15.3414 6.04732C15.3307 6.07254 15.3263 6.10004 15.3288 6.12735C15.3313 6.15467 15.3405 6.18094 15.3556 6.20382C15.3707 6.2267 15.3913 6.24547 15.4154 6.25846C15.4396 6.27145 15.4666 6.27824 15.494 6.27824H19.6811C19.7107 6.27825 19.7397 6.27036 19.7652 6.25538C19.7907 6.2404 19.8117 6.21888 19.8261 6.19304C19.8405 6.16721 19.8477 6.138 19.847 6.10844C19.8463 6.07888 19.8377 6.05005 19.8221 6.02494L17.3459 2.03017C17.3299 2.00445 17.3071 1.98361 17.2801 1.9699C17.2531 1.95619 17.2229 1.95013 17.1927 1.9524C17.1625 1.95466 17.1334 1.96516 17.1088 1.98274C17.0841 2.00032 17.0647 2.02433 17.0528 2.05214Z"
															fill="#1AADFF"
														/>
														<path
															d="M3.16829 2.03183L0.692917 6.02493C0.677321 6.05005 0.668724 6.07888 0.668016 6.10844C0.667309 6.13799 0.674517 6.1672 0.688893 6.19304C0.703269 6.21887 0.724291 6.2404 0.749782 6.25537C0.775272 6.27035 0.804304 6.27824 0.833869 6.27823H5.02099C5.04843 6.2782 5.07544 6.27136 5.09958 6.25832C5.12373 6.24528 5.14426 6.22645 5.15934 6.20353C5.17442 6.1806 5.18358 6.15429 5.18598 6.12696C5.18839 6.09962 5.18398 6.07211 5.17314 6.0469L3.45849 2.0538C3.44623 2.02667 3.42689 2.00335 3.40251 1.98628C3.37812 1.9692 3.34959 1.959 3.31991 1.95676C3.29023 1.95451 3.26048 1.96029 3.23381 1.9735C3.20713 1.98671 3.1845 2.00686 3.16829 2.03183Z"
															fill="#1AADFF"
														/>
														<path
															d="M15.9477 0.971781H10.747C10.732 0.971683 10.7172 0.975787 10.7044 0.983627C10.6916 0.991467 10.6813 1.00273 10.6746 1.01614C10.6678 1.02956 10.665 1.04459 10.6664 1.05953C10.6677 1.07448 10.6732 1.08874 10.6823 1.10071L13.9955 5.51419C14.004 5.52552 14.0153 5.53443 14.0283 5.54001C14.0413 5.54558 14.0555 5.54764 14.0695 5.54595C14.0836 5.54427 14.0969 5.53892 14.1082 5.53043C14.1196 5.52194 14.1284 5.51062 14.134 5.4976L15.8785 1.4278L16.0194 1.08206C16.0244 1.07019 16.0264 1.05729 16.0253 1.04448C16.0242 1.03166 16.02 1.0193 16.0131 1.00846C16.0061 0.997618 15.9967 0.988619 15.9855 0.982231C15.9744 0.975843 15.9618 0.972257 15.9489 0.971781H15.9477Z"
															fill="#1AADFF"
														/>
														<path
															d="M9.55205 18.2177L5.84458 7.7089C5.8337 7.67838 5.81362 7.65199 5.78712 7.63336C5.76061 7.61472 5.72898 7.60476 5.69658 7.60484H0.709347C0.680457 7.60476 0.652124 7.6128 0.627577 7.62803C0.603031 7.64327 0.583258 7.66509 0.570511 7.69102C0.557764 7.71694 0.552555 7.74593 0.55548 7.77467C0.558404 7.80341 0.569343 7.83076 0.58705 7.85358L9.54169 19.4536C9.572 19.4928 9.61525 19.5201 9.66374 19.5304C9.71224 19.5408 9.76285 19.5337 9.8066 19.5103C9.85059 19.4872 9.88481 19.449 9.903 19.4027C9.92118 19.3565 9.92214 19.3052 9.90568 19.2583L9.55205 18.2177Z"
															fill="#1AADFF"
														/>
														<path
															d="M19.8053 7.60484H14.818C14.7858 7.60484 14.7544 7.61485 14.7281 7.63349C14.7018 7.65213 14.6819 7.67848 14.6713 7.7089L10.6002 19.2405C10.5831 19.2889 10.5838 19.3418 10.6022 19.3897C10.6206 19.4377 10.6555 19.4775 10.7005 19.5021C10.7456 19.5265 10.7979 19.5341 10.8481 19.5235C10.8983 19.513 10.9431 19.485 10.9746 19.4444L19.9275 7.85358C19.9453 7.83076 19.9562 7.80341 19.9591 7.77467C19.962 7.74593 19.9568 7.71694 19.9441 7.69102C19.9313 7.66509 19.9116 7.64327 19.887 7.62803C19.8625 7.6128 19.8341 7.60476 19.8053 7.60484Z"
															fill="#1AADFF"
														/>
														<path
															d="M10.3895 2.91733L12.711 6.0129C12.7295 6.03753 12.7408 6.06683 12.7435 6.0975C12.7463 6.12817 12.7405 6.15901 12.7267 6.18655C12.7129 6.2141 12.6917 6.23726 12.6655 6.25345C12.6393 6.26964 12.6092 6.27822 12.5784 6.27822H7.93522C7.90442 6.27822 7.87423 6.26964 7.84804 6.25345C7.82184 6.23726 7.80067 6.2141 7.7869 6.18655C7.77312 6.15901 7.76729 6.12817 7.77006 6.0975C7.77283 6.06683 7.78408 6.03753 7.80256 6.0129L10.1241 2.91733C10.1396 2.89673 10.1596 2.88002 10.1826 2.8685C10.2057 2.85699 10.231 2.851 10.2568 2.851C10.2825 2.851 10.3079 2.85699 10.3309 2.8685C10.354 2.88002 10.374 2.89673 10.3895 2.91733ZM10.0993 15.7817L7.29264 7.8258C7.28382 7.8008 7.28113 7.77405 7.28479 7.74779C7.28844 7.72154 7.29834 7.69654 7.31365 7.6749C7.32895 7.65326 7.34923 7.6356 7.37276 7.62341C7.3963 7.61122 7.42242 7.60485 7.44893 7.60483H13.0646C13.0912 7.60485 13.1173 7.61122 13.1408 7.62341C13.1644 7.6356 13.1846 7.65326 13.1999 7.6749C13.2152 7.69654 13.2251 7.72154 13.2288 7.74779C13.2324 7.77405 13.2298 7.8008 13.2209 7.8258L10.4131 15.7817C10.4016 15.814 10.3805 15.842 10.3525 15.8618C10.3245 15.8815 10.2911 15.8921 10.2568 15.8921C10.2225 15.8921 10.1891 15.8815 10.1611 15.8618C10.1331 15.842 10.1119 15.814 10.1005 15.7817H10.0993Z"
															fill="#1AADFF"
														/>
													</svg>
												</span>
											</div>
											<div class="take_price">
												<h2 className="h2">&#8377;13999</h2>
												<p>For 6 Months</p>
											</div>

											<ul>
												<li>72 Live Classes</li>
												<li>6 Months Duration</li>
											</ul>
											<div class="take_price_btn">
												<Link to={link ? '/student-dashboard' : '/login'}>
													<button class="take_btn price_btn">Select</button>
												</Link>
											</div>
										</div>
									</div>
								</div>
							)}

							{isIndia === 1 && (
								<div class="row justify-content-center">
									<div class="col-lg-4 col-md-4 col-sm-6 ">
										<div class="take_pricing_box">
											<div class="take_pricetitle">
												<h2 className="h2">Monthly </h2>
												<span class="take_pricetag">
													<svg
														width="17"
														height="16"
														viewBox="0 0 17 16"
														fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<rect
															x="0.919922"
															y="0.677429"
															width="15.1613"
															height="15.1613"
															rx="3.03226"
															fill="#F68B00"
														/>
													</svg>
												</span>
											</div>
											<div class="take_price">
												<h2 className="h2">
													$69 <small>USD</small>
												</h2>
												<p>Per Month</p>
											</div>

											<ul>
												<li>8 Live Classes</li>
												<li>1 Month Duration</li>
											</ul>
											<div class="take_price_btn">
												<Link to={link ? '/student-dashboard' : '/login'}>
													<button class="take_btn price_btn">Select</button>
												</Link>
											</div>
										</div>
									</div>
									<div class="col-lg-4 col-md-4 col-sm-6 ">
										<div class="take_pricing_box pink">
											<div class="take_pricetitle">
												<h2 className="h2">Quarterly </h2>
												<span class="take_pricetag">
													<svg
														width="25"
														height="24"
														viewBox="0 0 25 24"
														fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M24.223 8.49487L17.0244 7.44869L13.8065 0.924931C13.7186 0.746314 13.574 0.60172 13.3954 0.513829C12.9474 0.292685 12.403 0.476972 12.1791 0.924931L8.96112 7.44869L1.76256 8.49487C1.5641 8.52322 1.38264 8.61678 1.24372 8.75854C1.07577 8.93117 0.983218 9.16341 0.986408 9.40423C0.989598 9.64506 1.08827 9.87477 1.26073 10.0429L6.46898 15.1207L5.23851 22.2909C5.20965 22.4577 5.22811 22.6292 5.29179 22.7861C5.35546 22.9429 5.46181 23.0787 5.59877 23.1782C5.73573 23.2777 5.89782 23.3368 6.06667 23.3488C6.23551 23.3609 6.40435 23.3254 6.55404 23.2463L12.9928 19.8611L19.4315 23.2463C19.6073 23.3399 19.8114 23.3711 20.007 23.3371C20.5004 23.252 20.8321 22.7842 20.747 22.2909L19.5165 15.1207L24.7248 10.0429C24.8666 9.90396 24.9601 9.72251 24.9885 9.52404C25.065 9.02789 24.7191 8.56859 24.223 8.49487Z"
															fill="#FF697B"
														/>
													</svg>
												</span>
											</div>
											<div class="take_price">
												<h2 className="h2">
													$199 <small>USD</small>
												</h2>
												<p>For 3 Months</p>

												<span class="popular">Most Popular</span>
											</div>

											<ul>
												<li>24 Live Classes</li>
												<li>3 Months Duration</li>
											</ul>
											<div class="take_price_btn">
												<Link to={link ? '/student-dashboard' : '/login'}>
													<button class="take_btn price_btn">Select</button>
												</Link>
											</div>
										</div>
									</div>
									<div class="col-lg-4 col-md-4 col-sm-6 ">
										<div class="take_pricing_box blue">
											<div class="take_pricetitle">
												<h2 className="h2">Semestral</h2>
												<span class="take_pricetag">
													<svg
														width="20"
														height="20"
														viewBox="0 0 20 20"
														fill="none"
														xmlns="http://www.w3.org/2000/svg">
														<path
															d="M4.69079 0.971786C4.66328 0.971533 4.63614 0.978128 4.61181 0.990978C4.58749 1.00383 4.56674 1.02253 4.55144 1.04539C4.53614 1.06826 4.52677 1.09457 4.52418 1.12196C4.52158 1.14935 4.52584 1.17695 4.53657 1.20228L4.63192 1.42739L6.31921 5.36163C6.33067 5.38839 6.34898 5.41166 6.37229 5.4291C6.3956 5.44654 6.42309 5.45753 6.452 5.46098C6.48091 5.46442 6.51021 5.4602 6.53697 5.44873C6.56373 5.43725 6.58699 5.41894 6.60443 5.39563L9.71865 1.23711C9.73713 1.21247 9.74838 1.18318 9.75115 1.1525C9.75391 1.12183 9.74808 1.091 9.73431 1.06345C9.72054 1.03591 9.69937 1.01274 9.67317 0.996552C9.64697 0.980362 9.61679 0.971786 9.58599 0.971786H4.69079Z"
															fill="#1AADFF"
														/>
														<path
															d="M17.0528 2.05214L15.3414 6.04732C15.3307 6.07254 15.3263 6.10004 15.3288 6.12735C15.3313 6.15467 15.3405 6.18094 15.3556 6.20382C15.3707 6.2267 15.3913 6.24547 15.4154 6.25846C15.4396 6.27145 15.4666 6.27824 15.494 6.27824H19.6811C19.7107 6.27825 19.7397 6.27036 19.7652 6.25538C19.7907 6.2404 19.8117 6.21888 19.8261 6.19304C19.8405 6.16721 19.8477 6.138 19.847 6.10844C19.8463 6.07888 19.8377 6.05005 19.8221 6.02494L17.3459 2.03017C17.3299 2.00445 17.3071 1.98361 17.2801 1.9699C17.2531 1.95619 17.2229 1.95013 17.1927 1.9524C17.1625 1.95466 17.1334 1.96516 17.1088 1.98274C17.0841 2.00032 17.0647 2.02433 17.0528 2.05214Z"
															fill="#1AADFF"
														/>
														<path
															d="M3.16829 2.03183L0.692917 6.02493C0.677321 6.05005 0.668724 6.07888 0.668016 6.10844C0.667309 6.13799 0.674517 6.1672 0.688893 6.19304C0.703269 6.21887 0.724291 6.2404 0.749782 6.25537C0.775272 6.27035 0.804304 6.27824 0.833869 6.27823H5.02099C5.04843 6.2782 5.07544 6.27136 5.09958 6.25832C5.12373 6.24528 5.14426 6.22645 5.15934 6.20353C5.17442 6.1806 5.18358 6.15429 5.18598 6.12696C5.18839 6.09962 5.18398 6.07211 5.17314 6.0469L3.45849 2.0538C3.44623 2.02667 3.42689 2.00335 3.40251 1.98628C3.37812 1.9692 3.34959 1.959 3.31991 1.95676C3.29023 1.95451 3.26048 1.96029 3.23381 1.9735C3.20713 1.98671 3.1845 2.00686 3.16829 2.03183Z"
															fill="#1AADFF"
														/>
														<path
															d="M15.9477 0.971781H10.747C10.732 0.971683 10.7172 0.975787 10.7044 0.983627C10.6916 0.991467 10.6813 1.00273 10.6746 1.01614C10.6678 1.02956 10.665 1.04459 10.6664 1.05953C10.6677 1.07448 10.6732 1.08874 10.6823 1.10071L13.9955 5.51419C14.004 5.52552 14.0153 5.53443 14.0283 5.54001C14.0413 5.54558 14.0555 5.54764 14.0695 5.54595C14.0836 5.54427 14.0969 5.53892 14.1082 5.53043C14.1196 5.52194 14.1284 5.51062 14.134 5.4976L15.8785 1.4278L16.0194 1.08206C16.0244 1.07019 16.0264 1.05729 16.0253 1.04448C16.0242 1.03166 16.02 1.0193 16.0131 1.00846C16.0061 0.997618 15.9967 0.988619 15.9855 0.982231C15.9744 0.975843 15.9618 0.972257 15.9489 0.971781H15.9477Z"
															fill="#1AADFF"
														/>
														<path
															d="M9.55205 18.2177L5.84458 7.7089C5.8337 7.67838 5.81362 7.65199 5.78712 7.63336C5.76061 7.61472 5.72898 7.60476 5.69658 7.60484H0.709347C0.680457 7.60476 0.652124 7.6128 0.627577 7.62803C0.603031 7.64327 0.583258 7.66509 0.570511 7.69102C0.557764 7.71694 0.552555 7.74593 0.55548 7.77467C0.558404 7.80341 0.569343 7.83076 0.58705 7.85358L9.54169 19.4536C9.572 19.4928 9.61525 19.5201 9.66374 19.5304C9.71224 19.5408 9.76285 19.5337 9.8066 19.5103C9.85059 19.4872 9.88481 19.449 9.903 19.4027C9.92118 19.3565 9.92214 19.3052 9.90568 19.2583L9.55205 18.2177Z"
															fill="#1AADFF"
														/>
														<path
															d="M19.8053 7.60484H14.818C14.7858 7.60484 14.7544 7.61485 14.7281 7.63349C14.7018 7.65213 14.6819 7.67848 14.6713 7.7089L10.6002 19.2405C10.5831 19.2889 10.5838 19.3418 10.6022 19.3897C10.6206 19.4377 10.6555 19.4775 10.7005 19.5021C10.7456 19.5265 10.7979 19.5341 10.8481 19.5235C10.8983 19.513 10.9431 19.485 10.9746 19.4444L19.9275 7.85358C19.9453 7.83076 19.9562 7.80341 19.9591 7.77467C19.962 7.74593 19.9568 7.71694 19.9441 7.69102C19.9313 7.66509 19.9116 7.64327 19.887 7.62803C19.8625 7.6128 19.8341 7.60476 19.8053 7.60484Z"
															fill="#1AADFF"
														/>
														<path
															d="M10.3895 2.91733L12.711 6.0129C12.7295 6.03753 12.7408 6.06683 12.7435 6.0975C12.7463 6.12817 12.7405 6.15901 12.7267 6.18655C12.7129 6.2141 12.6917 6.23726 12.6655 6.25345C12.6393 6.26964 12.6092 6.27822 12.5784 6.27822H7.93522C7.90442 6.27822 7.87423 6.26964 7.84804 6.25345C7.82184 6.23726 7.80067 6.2141 7.7869 6.18655C7.77312 6.15901 7.76729 6.12817 7.77006 6.0975C7.77283 6.06683 7.78408 6.03753 7.80256 6.0129L10.1241 2.91733C10.1396 2.89673 10.1596 2.88002 10.1826 2.8685C10.2057 2.85699 10.231 2.851 10.2568 2.851C10.2825 2.851 10.3079 2.85699 10.3309 2.8685C10.354 2.88002 10.374 2.89673 10.3895 2.91733ZM10.0993 15.7817L7.29264 7.8258C7.28382 7.8008 7.28113 7.77405 7.28479 7.74779C7.28844 7.72154 7.29834 7.69654 7.31365 7.6749C7.32895 7.65326 7.34923 7.6356 7.37276 7.62341C7.3963 7.61122 7.42242 7.60485 7.44893 7.60483H13.0646C13.0912 7.60485 13.1173 7.61122 13.1408 7.62341C13.1644 7.6356 13.1846 7.65326 13.1999 7.6749C13.2152 7.69654 13.2251 7.72154 13.2288 7.74779C13.2324 7.77405 13.2298 7.8008 13.2209 7.8258L10.4131 15.7817C10.4016 15.814 10.3805 15.842 10.3525 15.8618C10.3245 15.8815 10.2911 15.8921 10.2568 15.8921C10.2225 15.8921 10.1891 15.8815 10.1611 15.8618C10.1331 15.842 10.1119 15.814 10.1005 15.7817H10.0993Z"
															fill="#1AADFF"
														/>
													</svg>
												</span>
											</div>
											<div class="take_price">
												<h2 className="h2">
													{' '}
													$389 <small>USD</small>
												</h2>
												<p>For 6 Months</p>
											</div>

											<ul>
												<li>48 Live Classes</li>
												<li>6 Months Duration</li>
											</ul>
											<div class="take_price_btn">
												<Link to={link ? '/student-dashboard' : '/login'}>
													<button class="take_btn price_btn">Select</button>
												</Link>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};
export default Pricing;
