import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import About from './About';
import Blogdetails1 from './Blogdetails1';
import Blogdetails2 from './Blogdetails2';
import Blogdetails3 from './Blogdetails3';
import Blogs from './Blogs';
import BookedClasses from './BookedClasses';
import ChangePassword from './ChangePassword';
import Contact from './Contact';
import Course_schedule from './Course_schedule';
import Explore from './Explore';
import Faq from './Faq';
import FindClasses from './FindClasses';
import ForgotPassword from './ForgotPassword';
import ForgotPasswordMobile from './ForgotPasswordMobile';
import Home from './Home';
import Instruments from './Instruments';
import Login from './Login';
import LoginByadmin from './LoginByadmin';
import LoginWithEmail from './LoginWithEmail';
import Message from './Message';
import NotFound from './NotFound';
import Payments from './Payments';
import PaymentStripe from './PaymentStripe';
import PaymentStripeSuccess from './PaymentStripeSuccess';
import plans from './Plans';
import Pricing from './Pricing';
import Privacy from './Privacy';
import Profile from './Profile';
import Register from './Register';
import RegisterTeacher from './RegisterTeacher';
import RegisterPassword from './RegisterPassword';
import RegisterWithPin from './RegisterWithPin';
import StudentDashboard from './StudentDashboard';
import StudentProfile from './StudentProfile';
import Workshop from './Summercamp';
import Summercamp_Thanks from './Summercamp_Thanks';
import Summercamp_Verify_OTP from './Summercamp_Verify_OTP';
import TeacherProfile from './TeacherProfile';
import Teachers from './Teachers';
import Team from './Team';
import Term from './Term';
import TrasactionHistory from './TrasactionHistory';
import VerifyOTP from './VerifyOTP';
import verifyRegisterOTP from './VerifyOTPForgotPassword';
import VerifyPasswordConfirm from './VerifyPasswordConfirm';

const hashHistory = Router.hashHistory;

const Routes = (props) => (
	<Router {...props} history={hashHistory}>
		<Switch>
			<Route exact path="/" component={Home} />
			<Route path="/contact" component={Contact} />
			<Route path="/teachers-:instrumentid" component={Teachers} />
			<Route path="/teacher-profile-:teacherId" component={TeacherProfile} />

			<Route path="/instruments" component={Instruments} />

			<Route path="/register" component={Register} />
			<Route path="/registerwithpin" component={RegisterWithPin} />
			<Route path="/login" component={Login} />
			<Route path="/about" component={About} />
			<Route path="/LoginWithEmail" component={LoginWithEmail} />

			<Route path="/student-dashboard-:types" component={StudentDashboard} />
			<Route path="/student-dashboard" component={StudentDashboard} />
			<Route path="/student-profile" component={StudentProfile} />
			<Route path="/change-password" component={ChangePassword} />
			<Route path="/find-classes-:teacherid" component={FindClasses} />
			<Route path="/plans-:class_id" component={plans} />

			<Route path="/book-class-:class_id" component={plans} />

			<Route path="/terms-condition" component={Term} />
			<Route path="/privacy-policy" component={Privacy} />

			<Route path="/payment-history" component={TrasactionHistory} />

			<Route path="/booked-classes" component={BookedClasses} />

			<Route path="/forgot_password" component={ForgotPassword} />
			<Route path="/forgot_password_mobile" component={ForgotPasswordMobile} />
			<Route path="/verify-OTP" component={VerifyOTP} />
			<Route path="/explore" component={Explore} />

			<Route path="/verify-OTP-forgot" component={verifyRegisterOTP} />
			<Route
				path="/verify-password-confirm"
				component={VerifyPasswordConfirm}
			/>
			<Route path="/team" component={Team} />
			<Route path="/faq" component={Faq} />
			<Route path="/blog" component={Blogs} />
			<Route path="/register-password" component={RegisterPassword} />
			<Route path="/profile" component={Profile} />
			<Route path="/course-schedule" component={Course_schedule} />
			<Route path="/pricing" component={Pricing} />
			<Route path="/loginByadmin-:stid" component={LoginByadmin} />

			<Route path="/PaymentStripe" component={PaymentStripe} />
			<Route path="/paymentSuccess" component={PaymentStripeSuccess} />
			<Route path="/message" component={Message} />

			<Route path="/payments-history" component={Payments} />
			<Route path="/workshop" component={Workshop} />
			<Route path="/workshop_thanks" component={Summercamp_Thanks} />
			<Route path="/workshop_verify_OTP" component={Summercamp_Verify_OTP} />
			<Route path="/blog001" component={Blogdetails1} />
			<Route path="/blog002" component={Blogdetails2} />
			<Route path="/blog003" component={Blogdetails3} />
			<Route path='/registerTeacher' component={RegisterTeacher} />
			<Route path="*" component={NotFound} />
		</Switch>
	</Router>
);
export default Routes;
