import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import React, {useEffect, useState} from 'react';
import {useAlert} from 'react-alert';
import Api from '../Api';
import Helper from '../auth/Helper';
import Sidebar_inner from './Sidebar_Inner';

const Course_schedule = (props) => {
	const [userId, setUserId] = useState(0);
	const [allClassData, setAllClassData] = useState(null);

	useEffect(async () => {
		await Helper.getAtuhData().then((result) => {
			const userId = result.id;
			setUserId(userId);

			Api.getScheduledClass(userId).then((response) => {
				localStorage.setItem(
					'allClassData',
					JSON.stringify(response.data.data)
				);
				setAllClassData(JSON.stringify(response.data.data));
			});
		});
	}, [setUserId, setAllClassData]);

	const eventGuid = 0;
	const todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
	const INITIAL_EVENTS = [
		{
			id: 1,
			title: 'All-day event',
			start: todayStr
		},
		{
			id: 2,
			title: 'Timed event',
			start: todayStr + 'T12:00:00'
		}
	];

	console.log(INITIAL_EVENTS);

	///let  INITIAL_EVENTS = allClassData;

	console.log(allClassData);

	const alert = useAlert();

	return (
		<div className="take_admin_main_wrapper">
			<Sidebar_inner />

			<div class="take_top_header">
				<div class="take_topheader_inner">
					<div class="take_logo d-none ">
						<a href="#">
							<img src="assets/images/logo.png" alt="" />
						</a>
					</div>
					<span class="take_toggle d-none ">
						<img src="assets/images/toggle.png" alt="" />
					</span>
					<div class="take_search_wrapper d-none">
						<input
							type="text"
							name=""
							id=""
							class="form-control"
							placeholder="Search instruments, teachers"
						/>
						<span>
							<img src="assets/images/svg/search.svg" alt="" />
						</span>
					</div>
				</div>

				<h2 class="take_title h2">Course Schedule</h2>
			</div>
			<div class="take_body take_schedule_wrapper">
				{allClassData && (
					<FullCalendar
						// plugins={[ dayGridPlugin ]}
						plugins={[dayGridPlugin]}
						initialView="dayGridMonth"
						headerToolbar={{
							left: 'prev,next today',
							center: 'title',
							right: 'dayGridMonth'
						}}
						initialEvents={JSON.parse(allClassData)} // alternatively, use the `events` setting to fetch from a feed
					/>
				)}
			</div>
		</div>
	);
};
export default Course_schedule;
