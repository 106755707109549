import React, {useEffect, useRef, useState} from 'react';
import {useAlert} from 'react-alert';
import {Col, Dropdown, DropdownButton, Row} from 'react-bootstrap';
import 'react-calendar/dist/Calendar.css';
import 'react-datepicker/dist/react-datepicker.css';
import {useForm} from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Api from '../Api';
import Loader from './Loader';
import './main.css';

const Workshop = (props) => {
	const fNameRef = useRef();
	const lNameRef = useRef();
	const emailRef = useRef();

	const [mobile, setMobile] = useState();
	const [dialCode, setDialCode] = useState();
	const [phoneCountryCode, setPhoneCountryCode] = useState();

	const [isLoading, setLaoding] = useState(false);
	const [workshops, setWorkshops] = useState(null);
	const [selectedWorkshop, setSelectedWorkshop] = useState(null);
	const [countryCode, setCountryCode] = useState('IN');

	useEffect(() => {
		async function fetchData() {
			const result = await Api.getWorkshopList();
			let workshopList = [];
			if (result?.status === 200) {
				workshopList = result?.data?.data ?? [];
				if (workshopList.length > 0) {
					setSelectedWorkshop(workshopList[0]);
				}
			}
			setWorkshops(workshopList);
		}
		fetchData();
	}, []);

	const onChangeNumber = (value, country, e, formattedValue) => {
		const country_Code = country.countryCode;
		const dial_Code = country.dialCode;
		const dial_Codelength = dial_Code.length;
		value = value.substring(dial_Codelength);
		setValue('mobile', value);
		setValue('dialCode', dial_Code);
		setValue('countryCode', country_Code);
		setMobile(value);
		setDialCode(dial_Code);
		setPhoneCountryCode(country_Code);
		setCountryCode(countryCode);
	};

	const alert = useAlert();
	const {
		register,
		setValue,
		formState: {errors}
	} = useForm({
		mode: 'onChange'
	});

	const onSubmit = (e) => {
		e.preventDefault();

		if (!selectedWorkshop?.id) return;
		const data = {
			first_name: fNameRef.current?.value ?? '',
			last_name: lNameRef.current?.value ?? '',
			email: emailRef.current?.value ?? '',
			mobile,
			dialCode,
			countryCode: phoneCountryCode,
			wid: selectedWorkshop?.id
		};

		if (
			data.first_name === '' ||
			data.last_name === '' ||
			data.email === '' ||
			!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
				data.email
			) ||
			data.mobile === '' ||
			data.mobile.length < 10
		) {
			alert.show('Enter Valid Details', {
				timeout: 2000, // custom timeout just for this one alert
				type: 'success'
			});
			return;
		}

		setLaoding(true);

		Api.registerWorkshop(JSON.stringify(data)).then((response) => {
			console.log(response.data.data);
			if (response.data.success) {
				e.target.reset(); // reset after form submit
				localStorage.setItem('registeruserid', response.data.data);
				alert.show(response.data.message, {
					timeout: 2000, // custom timeout just for this one alert
					type: 'success',
					onClose: () => {
						window.location.href = '/';
					}
				});
			} else {
				setLaoding(false);
				alert.show(response.data.message, {
					timeout: 3000, // custom timeout just for this one alert
					type: 'error'
				});
			}
		});
	};

	function getWorkshopList() {
		return (
			workshops &&
			selectedWorkshop && (
				<DropdownButton
					id="dropdown-basic-button"
					title={selectedWorkshop.title}
					className="mb-2 workshop-list"
					variant="dark">
					{workshops.map((workshop) => (
						<Dropdown.Item>
							<small>
								<b>{workshop.name}</b>
							</small>
							<Row>
								<Col>
									<small>{workshop.instrument_name}</small>
								</Col>
								<Col>
									<small>{workshop.teacher_name}</small>
								</Col>
							</Row>
							<Row>
								<Col>
									<small>{workshop.workshop_date}</small>
								</Col>
								<Col>
									<small>{workshop.workshop_time}</small>
								</Col>
							</Row>
						</Dropdown.Item>
					))}
				</DropdownButton>
			)
		);
	}

	function getWorkshop() {
		return (
			selectedWorkshop && (
				<>
					<div className="mt-4 m-2">
						<p>{selectedWorkshop.info}</p>
					</div>

					<div class=" d-flex mb-4 p-3 rounded workshop-row workshop-full">
						<div class="d-flex  col-lg-6 col-md-6 col-sm-6 summer-div">
							<div>
								<img
									src="assets/images/summer-colander.png"
									class="img-fluid"
									alt=""
								/>
							</div>
							<div class="summer-text1 ml-3">
								Instrument: <b>{selectedWorkshop?.instrument_name ?? ''}</b>
								<br />
								Tutor: <b>{selectedWorkshop?.teacher_name ?? ''}</b>
							</div>
						</div>
						<div class=" d-flex col-lg-6 col-md-6 col-sm-6">
							<div>
								<img
									src="assets/images/summer-clock.png"
									class="img-fluid"
									alt=""
								/>
							</div>
							<div class="summer-text1 ml-3">
								Date: <b>{selectedWorkshop?.workshop_date ?? ''}</b>
								<br />
								Time: <b>{selectedWorkshop?.workshop_time ?? ''}</b>
							</div>
						</div>
					</div>

					<div class=" d-flex mb-4 p-3 rounded workshop-row workshop-mobile">
						<div class="d-flex  col-lg-12 col-md-12 col-sm-12">
							<div class=" ml-3">
								<p className="mb-0">
									Instrument: <b>{selectedWorkshop?.instrument_name ?? ''}</b>
								</p>
								<p className="mb-0">
									Tutor: <b>{selectedWorkshop?.teacher_name ?? ''}</b>
								</p>
								<p className="mb-0">
									Date: <b>{selectedWorkshop?.workshop_date ?? ''}</b>
								</p>
								<p className="mb-0">
									Time: <b>{selectedWorkshop?.workshop_time ?? ''}</b>
								</p>
							</div>
						</div>
					</div>
				</>
			)
		);
	}

	return isLoading ? (
		<Loader />
	) : (
		<div>
			<div className="wrapper">
				<div className="image_holder">
					<img src={selectedWorkshop?.img ?? ''} className="image" alt="" />
				</div>
				<div className="register_form">
					<div class="auth_logo">
						<a href="/">
							<img src="assets/images/logonew.png" class="img-fluid" alt="" />
						</a>
					</div>
					{workshops && workshops.length === 0 ? (
						<h1 class="mb-0">No Active Workshops at this time!!</h1>
					) : workshops ? (
						<>
							<h1 class="mb-0">Free Live Music Workshop</h1>
							<p>No instrument Needed</p>
							{getWorkshopList()}

							{getWorkshop()}

							<form onSubmit={(e) => onSubmit(e)}>
								<div class="row">
									<div class="col-lg-6 col-md-6 col-sm-6">
										<div class="form-group">
											<input
												type="text"
												{...register('first_name', {required: true})}
												name="name"
												id=""
												placeholder="Student First Name"
												class="form-control"
												ref={fNameRef}
											/>
											{errors.name && (
												<p class="error">Student First Name is required.</p>
											)}
										</div>
									</div>

									<div class="col-lg-6 col-md-6 col-sm-6">
										<div class="form-group">
											<input
												type="text"
												{...register('last_name', {required: true})}
												name="last_name"
												placeholder="Student Last Name"
												id="last_name"
												class="form-control"
												ref={lNameRef}
											/>
											{errors.last_name && (
												<p class="error">Student Last Name is required.</p>
											)}
										</div>
									</div>

									<div class="col-lg-6 col-md-6 col-sm-6">
										<div class="form-group">
											<input
												type="text"
												{...register('email', {
													required: true,
													pattern: {
														value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
														message: 'Please enter a valid email'
													}
												})}
												name="email"
												id=""
												class="form-control"
												placeholder="Email Address"
												ref={emailRef}
											/>
											{errors.email && (
												<p class="error">Please enter a valid email Address.</p>
											)}
										</div>
									</div>

									<div class="col-lg-6 col-md-6 col-sm-6">
										<div class="form-group">
											<PhoneInput
												inputProps={{
													name: 'phone',
													required: true,
													autoFocus: true
												}}
												inputClass="form-control widthcls"
												onChange={onChangeNumber}
												country={countryCode.toLowerCase()}
												enableSearch
												containerClass=""
												placeholder=""
											/>
										</div>
										{errors.mobile && (
											<p class="error">Mobile Number is required.</p>
										)}

										<input
											type="text"
											name="mobile"
											{...register('mobile', {
												required: true,
												pattern: {
													value: /^[1-9]\d*(\.\d+)?$/,
													message: 'Enter a valid phone number'
												}
											})}
											style={{opacity: 0, width: '0%', height: '0%'}}
										/>
									</div>

									<div class="col-lg-12 text-center">
										<button class="take_btn auth_login_btn" type="submit">
											{' '}
											Register
										</button>
									</div>
								</div>
							</form>
						</>
					) : null}
				</div>
			</div>
		</div>
	);
};
export default Workshop;
